<template>

<div class="profiles">

	<div class="profiles-item" v-for="(profile, index) in block.profiles" :key="index">

		<div class="profiles-item-image" v-bgimage="profile.image"></div>

		<div class="profiles-item-name">{{ profile.name }}</div>
		<div class="profiles-item-role">{{ profile.role }}</div>
		<div class="profiles-item-email">{{ profile.email }}</div>
		<div class="profiles-item-phone">{{ profile.phone }}</div>

	</div>

</div>

</template>

<script>

import mixBlock from './mixin.js'

export default {

	mixins: [mixBlock]

}

</script>

<style scoped>

.profiles {
	display: flex;
	flex-wrap: wrap;
}

.profiles-item {
	width: calc(50% - 10px);
	margin-bottom: 20px;
}

.is-mobile .profiles-item {
	width: 100%;
}

.profiles-item:nth-child(2n) {
	margin-left: 20px;
}

.is-mobile .profiles-item:nth-child(2n) {
	margin-left: 0px;
}

.profiles-item-image {
	width: 258px;
	border-radius: 50%;
	border: 14px solid #b7b7b7;
	height: 258px;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	margin: 0px auto;
}

.is-mobile .profiles-item-image {
	width: 180px;
	height: 180px;
	border-width: 10px;
	margin: 0px auto 20px auto;
}

.is-tablet .profiles-item-image {
	width: 180px;
	height: 180px;
	border-width: 6px;
}

.profiles-item-name {
	margin-top: 20px;
	color: #343434;
	font-size: 32px;
	font-weight: bold;
	line-height: 30px;
}

.is-tablet .profiles-item-name {
	font-size: 24px;
}

.is-mobile .profiles-item-name {
	font-size: 20px;
	text-align: center;
}

.profiles-item-role {
	margin-top: 4px;
	color: #1277d4;
	font-size: 24px;
	line-height: 30px;
	margin-bottom: 10px;
}

.is-tablet .profiles-item-role {
	font-size: 20px;
	line-height: 24px;
}

.is-mobile .profiles-item-role {
	font-size: 16px;
	text-align: center;
	line-height: 20px;
}

.profiles-item-email,
.profiles-item-phone {
	color: #343434;
	font-size: 16px;
	line-height: 30px;
}

.is-mobile .profiles-item-email,
.is-mobile .profiles-item-phone {
	font-size: 14px;
	line-height: 20px;
	text-align: center;
}

</style>
